<template>
  <b-card :title="$t('User Additional Information')">
    <app-timeline v-if="Boolean(userData.additionalInfo)">

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item  v-for="(list, key, index) in JSON.parse(userData.additionalInfo)"  :index="key" :key="key">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6> {{ $t(key) }}  </h6>
          <small class="text-muted">{{ index+1 }} </small>
        </div>
        <p>{{ $t(list) }} </p>
        
      </app-timeline-item>
       
      
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
    props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>

<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="userData.photo" :text="avatarText(userData.fullname)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullname }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button :to="{ name: 'client-member-edit-page', params: { id: userData.id } }" variant="primary"
                v-if="showButtons">
                {{ $t('Edit') }}
              </b-button>
              <b-button variant="outline-danger" class="ml-1" v-if="showButtons" @click="memberDelete()">
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">


        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Username') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Member Code') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.membercode }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Country') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.country }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Contact') }}</span>
            </th>
            <td>
              {{ userData.mobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Branch') }}</span>
            </th>
            <td>
              {{ userData.platformbranchname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Department') }}</span>
            </th>
            <td>
              {{ userData.platformdepartmentname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Position') }}</span>
            </th>
            <td>
              {{ userData.platformpositionname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Title') }}</span>
            </th>
            <td>
              {{ userData.platformtitlename }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../member-list/useMembersList'
import store from '@/store'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import userStoreModule from '../memberStoreModule'
import Swal from 'sweetalert2'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-member-edit'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    const { resolveUserRoleVariant } = useUsersList()
    const memberDelete = () => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-member-edit/deleteUser', { id: router.currentRoute.params.id }).then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'User is deleted',
                icon: "CoffeeIcon",
                variant: "success",
              },
            })
            router.push({ name: 'client-members-page' })
          }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'User is not deleted',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })

    }
    return {
      avatarText,
      resolveUserRoleVariant,
      memberDelete
    }
  },
}
</script>

<style></style>

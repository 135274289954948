<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>{{ $t('Groups') }}</b-card-title>
      <b-card-sub-title>{{ $t('User is member of these groups') }}</b-card-sub-title>
    </b-card-body>
    <div class=" justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <b-row>
        <b-col cols="9">
          <group-picker @selectionChanged="selectionChanged"></group-picker>
        </b-col>
        <b-col cols="3">

          <b-button variant="outline-primary" class="mr-2" type="button" @click="changeCategory()">
            {{ $t('Update Groups') }}
          </b-button>

        </b-col>
      </b-row>
    </div>
    <b-table striped responsive :fields="tableColumns" :items="userData.ClientGroups" class="mb-0">

      <template #cell(name)="data">
        <h5>{{ data.item.name }}</h5>
      </template>
      <template #cell(remove)="data">
        <b-button variant=".btn-flat-dark" @click="removeFromGroup(data.item)">
          <feather-icon icon="TrashIcon" style="color: red;"/>
          <span style="color: red;" class="align-middle ml-50">{{ $t('Remove') }}</span>
        </b-button>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BRow,
  BCol,
} from 'bootstrap-vue'
import GroupPicker from '@/views/clientgroups/components/GroupSelector.vue'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton,
    GroupPicker, BRow,
    BCol,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedCategories: []

    }
  },
  methods: {
    selectionChanged(selectedGroup) {
      //console.log(selectedGroup);
      this.selectedCategories = selectedGroup;


    },
    removeFromGroup(groupInfo) {
      //console.log(this.selectedCategories);
      var newGroups = this.selectedCategories;
      var userId = this.userData.id;
      var groups = [groupInfo.id];
      var updateData = {
        members: this.userData.id,
        groupId: groups
      }

      //console.log("sending : ", updateData);
      axios({
        method: "post",
        url: `/admin/f/cm/removeMemberFromGroup`,
        data: updateData,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });


          const index = this.userData.ClientGroups.indexOf(groupInfo);
          if (index > -1) { // only splice array when item is found
            this.userData.ClientGroups.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
    },

    changeCategory(groups) {
      //console.log(this.selectedCategories);
      var newGroups = this.selectedCategories;
      var userId = this.userData.id;
      var groups = [];
      this.selectedCategories.forEach(function (data) {
        // code
        groups.push(data.id);

      });
      var updateData = {
        member: this.userData.id,
        groups: groups
      }

      //console.log("sending : ", updateData);
      axios({
        method: "post",
        url: `/admin/f/cm/addMembertoGroups`,
        data: updateData,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          for (let index = 0; index < this.selectedCategories.length; index++) {
            const element = this.selectedCategories[index];
            this.userData.ClientGroups.push(element);
          }



          //console.log("client groups ", this.userData.ClientGroups); 
          //console.log(" groups ", groups); 

          this.selectedCategories = [];

          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
              text: r.message
            },
          });
          this.isLoading = false;
        });

    }
  },
  setup() {
    const tableColumns = [
      { key: 'name', label: "Group", sortable: false },

      { key: 'remove', label: "Remove", sortable: false },

    ];
    return {
      tableColumns
    }
  },
}
</script>

<style></style>
